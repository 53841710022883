'use client'

import type { ErrorPageProps } from './error'
import React from 'react'
import { StyledEngineProvider } from '@mui/material/styles'
import ErrorPage from './error'

const GlobalError = ({ error, reset }: ErrorPageProps): JSX.Element => (
    <html lang="en">
        <head>
            {/* eslint-disable-next-line @next/next/no-page-custom-font */}
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
            />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        </head>
        <body>
            <StyledEngineProvider injectFirst>
                <ErrorPage error={error} reset={reset} />
            </StyledEngineProvider>
        </body>
    </html>
)

export default GlobalError
